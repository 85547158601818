document.addEventListener("DOMContentLoaded", () => {
	var btt = document.getElementById("backtotop");
	if (!btt) return;
	btt.addEventListener("click", () => {
		window.scrollTo({
			behavior: "smooth",
			top: 0,
			left: 0,
		});
	});
});



document.addEventListener("DOMContentLoaded", () => {
	const description = document.getElementById("category-description");
	if (!description) return;
	const cleanDesc = description.innerHTML.split(" ").filter((word) => word !== "" && word !== "\n");
	const cleanDescString = cleanDesc.join(" ");
	const words = cleanDescString
		.replace(/(<([^>]+)>)/gi, "")
		.trim()
		.split(" ");
	const nbWords = words.length;

	if (nbWords >= 200) {
		const descDisplay = cleanDesc.slice(0, 50 + 1).join(" ");
		const descHidden = cleanDesc.slice(50 + 1).join(" ");
		description.innerHTML = `<div class="desc"><div style="display:inline;" class="desc-display">${descDisplay.replace(/(<([^>]+)>)/gi, "")} </div>${descHidden.replace("<p></p>", "")}</div>`;
		document.querySelector(".desc").style.height = document.querySelector(".desc-display").offsetHeight + "px";
		document.querySelector(".desc").insertAdjacentHTML("afterend", '<div class="show-more" style="display:inline-block;">Voir plus <i></i></div><div style="display:none;" class="show-less">Voir moins<i></i></div>');
		description.style.height = "auto";
		description.style.opacity = 1;

		document.querySelector(".show-more").addEventListener("click", () => {
			document.querySelector(".desc").style.height = "auto";
			document.querySelector(".show-more").style.display = "none";
			document.querySelector(".show-less").style.display = "inline-block";
		});

		document.querySelector(".show-less").addEventListener("click", () => {
			document.querySelector(".desc").style.height = document.querySelector(".desc-display").offsetHeight + "px";
			document.querySelector(".show-less").style.display = "none";
			document.querySelector(".show-more").style.display = "inline-block";
		});
	}
});
/* document.addEventListener("DOMContentLoaded", () => {


	// readmore
	var rdmores = document.querySelectorAll(".m1-read-more");
	if (rdmores.length > 0) {
		rdmores.forEach((rdmore) => {
			let rdmoreBTN = rdmore.querySelector(".m1-read-more-btn"),
				rdmoreCONTENT = rdmore.querySelector(".m1-read-more-content");

			function openContent(content) {
				content.style.height = content.scrollHeight + "px";
				content.classList.remove("closed");
				if (rdmoreBTN.querySelector("span")) {
					rdmoreBTN.querySelector("span").textContent =
						rdmoreBTN.dataset.close;
				}
			}
			function closeContent(content) {
				content.style.height = "96px";
				content.classList.add("closed");
				if (rdmoreBTN.querySelector("span")) {
					rdmoreBTN.querySelector("span").textContent =
						rdmoreBTN.dataset.open;
				}
			}

			closeContent(rdmoreCONTENT);

			rdmoreBTN.addEventListener("click", (e) => {
				if (rdmoreCONTENT.classList.contains("closed")) {
					openContent(rdmoreCONTENT);
				} else {
					closeContent(rdmoreCONTENT);
				}
			});
		});
	}



/********* PRODUCT OPTIONS FAKE SELECT  */

document.addEventListener("DOMContentLoaded", () => {
	let isOpen = false;
	const selector = document.querySelector(".m1-select-with-thumb");
	if (!selector) return;
	// function to handle selector opening
	const handleSelectorOpening = () => {
		isOpen = !isOpen;
		if (isOpen) {
			selector.classList.add("opened");
			selector.style.height = selector.scrollHeight + "px";
		} else {
			selector.classList.remove("opened");
			selector.style.height = "";
		}
	};
	// handle selector open/close
	selector.addEventListener("click", () => {
		handleSelectorOpening();
	});

	// handle set value on selector child click
	const selectorChildren = selector.querySelectorAll("li");
	var value = selectorChildren[0].dataset.value;
	if (selectorChildren.length < 1) return;

	selectorChildren.forEach((child) => {
		child.addEventListener("click", (e) => {
			const selectedValue = child.dataset.value;
			if (selectedValue !== value) {
				// console.log(selectedValue, value)
				value = selectedValue;
				// push child on top of the selector children
				//selector.insertBefore(child, selector.firstChild);
				window.location.href = selectedValue;
			}
		});
	});
});
